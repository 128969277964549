<template>
  <b-overlay
    :show="loading"
    spinner-variant="primary"
  >
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- left -->
            <h5>Страницы</h5>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                @click="$router.push({ name: 'sites.store.pages.create', params: { siteId: siteId } })"
              >
                <span class="text-nowrap">Создать</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refPageListTable"
        class="position-relative list-view-table"
        :items="items"
        responsive
        :fields="[
          { key: 'name', label: 'Название', sortable: true },
          { key: 'type', label: 'Тип', sortable: true },
          { key: 'is_published', label: 'Активность', sortable: true },
          { key: 'actions', label: 'Действия' },
        ]"
        primary-key="key"
        :sort-by="'name'"
        show-empty
        empty-text="Ничего не найдено!"
        :sort-desc="false"
      >
        <template #cell(type)="data">
          {{ data.item.type === 'page' ? 'Пользовательская' : 'Системная' }}
        </template>
        <template #cell(is_published)="data">
          <b-badge
            pill
            variant=""
            class="text-capitalize"
            :variant="data.item.is_published ? 'light-success' : 'light-secondary'"
          >
            {{ data.item.is_published ? 'Вкл' : 'Выкл' }}
          </b-badge>
        </template>
        <template #cell(name)="data">

          <b-link
            v-if="data.item.type === 'page'"
            :to="{ name: 'sites.store.pages.content', params: { id: data.item.id } }"
          >
            {{ data.item.name }}
          </b-link>

          <div
            v-else
            class=""
          >
            {{ data.item.name }}
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            v-if="data.item.type === 'page'"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'sites.store.pages.content', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Содержимое</span>
            </b-dropdown-item>

            <template>
              <b-dropdown-item :to="{ name: 'sites.store.pages.edit', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Настройки</span>
              </b-dropdown-item>

              <b-dropdown-item @click="deleteItem(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Удалить</span>
              </b-dropdown-item>
            </template>

          </b-dropdown>
        </template>
      </b-table>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BDropdown, BDropdownItem, BOverlay, BLink, BBadge, BAlert,
} from 'bootstrap-vue'

import { ref, onMounted } from 'vue'
import get from 'lodash/get'
import { getPages, deletePage } from '@/services/main-api/websites/stores/pages'
import useDeleteItem from '@/composables/useDeleieItem'

export default {
  name: 'PageListView',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BLink,
    BBadge,
    BAlert,
  },
  props: {
    siteId: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props) {
    const loading = ref(false)

    const items = ref([])
    const getItems = async () => {
      try {
        const res = await getPages({
          websiteID: props.siteId,
          orderBy: 'name',
          sortedBy: 'ask',
          perPage: 1000,
        })

        items.value = res.data.data
      } catch (e) {
        items.value = []
      }
    }

    const getData = async () => {
      loading.value = true
      await getItems()
      loading.value = false
    }

    const { deleteItem } = useDeleteItem({
      deleteHandler: item => deletePage(item.id),
      getQuestionTextBeforeDeletion: item => `Страница <strong>${item.name}</strong> будет удалена`,
      getTextAfterDeletion: () => 'Страница была удалена.',
      runAfterDeletion: async () => {
        await getData()
      },
    })

    onMounted(() => {
      getData()
    })

    return {
      loading,
      items,

      deleteItem,

      get,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.list-view-table{
  min-height: 145px;
}
</style>
