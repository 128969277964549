<template>
  <div v-if="getItemError">
    <b-alert
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        Ошибка при получении данных сайта
      </h4>
      <div class="alert-body">
        Не найден сайт с этим идентификатором. Можете перейти в
        <b-link
          class="alert-link"
          :to="{ name: 'sites'}"
        >
          Список сайтов
        </b-link>
        и выбрать другой.
      </div>
    </b-alert>
  </div>
  <b-overlay
    v-else
    :show="loading"
    spinner-variant="primary"
  >
    <app-breadcrumb
      :breadcrumb="breadcrumb"
      :page-title="pageTitle"
    />
    <b-card>
      <b-row>
        <b-col>
          <b-table-simple
            :striped="true"
            :bordered="false"
            :borderless="true"
            :outlined="false"
            :small="false"
            :hover="false"
            :dark="false"
            :fixed="false"
            :responsive="true"
            class="mt-1"
          >
            <b-tbody>
              <b-tr>
                <b-th>
                  Домен
                </b-th>
                <b-td>
                  <template v-if="item.domain">
                    <a
                      target="_blank"
                      :href="'https://' + item.domain"
                    >{{ item.domain }}</a>
                  </template>
                  <template v-else>
                    -
                  </template>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Ключ
                </b-th>
                <b-td>
                  {{ item.key }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Тип
                </b-th>
                <b-td>
                  Интернет-магазин
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Режим работы
                </b-th>
                <b-td>
                  {{ get(siteModes.find(t => t.value === item.mode), 'name') }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Описание
                </b-th>
                <b-td>
                  {{ item.description || '-' }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  URL
                </b-th>
                <b-td>
                  <b-link
                    target="_blank"
                    :href="siteUrl.path"
                  >
                    {{ siteUrl.label }}
                  </b-link>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <div class="d-flex flex-wrap">
            <b-button
              v-if="item.id"
              :to="{ name: 'sites.store.edit', params: { id: item.id } }"
              variant="primary"
            >
              Изменить
            </b-button>
            <b-button
              variant="outline-danger"
              class="ml-1"
              @click="deleteItem(item)"
            >
              Удалить
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <!--    <page-list-->
    <!--      v-if="item.id"-->
    <!--      :site-id="item.id"-->
    <!--    />-->
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BOverlay, BButton, BAlert, BLink, BBadge, BTabs, BTab,
  BFormGroup, BFormInput, BFormTextarea, BCardHeader, BCardTitle, BTable, BFormCheckbox,
  BTableSimple, BTbody, BTr, BTd, BTh, BListGroup, BListGroupItem, BFormRow,
} from 'bootstrap-vue'
import get from 'lodash/get'
import { getCurrentInstance, ref, computed } from 'vue'
import siteModes from '@/modules/sites-section/sites/composables/siteModes'

import {
  deleteWebsite as deleteWebsiteApi,
  getWebsite as getWebsiteApi,
} from '@/services/main-api/websites/stores/websites'
import useCrudShow from '@/composables/useCrudShow'
import appBreadcrumb from '@/layouts/components/AppBreadcrumb.vue'
import PageList from '@/modules/sites-section/site-store-section/pages/views/ListView.vue'

export default {
  name: 'ShowItemView',
  components: {
    BCard,
    BRow,
    BCol,
    BOverlay,
    BButton,
    BAlert,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCardHeader,
    BCardTitle,
    BTable,
    BFormCheckbox,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BTh,
    BListGroup,
    BListGroupItem,
    BFormRow,
    appBreadcrumb,
    PageList,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props) {
    const instance = getCurrentInstance().proxy
    const getItemError = ref(false)

    const getItem = async () => {
      try {
        const res = await getWebsiteApi({ id: props.id })
        instance.item = res.data.data
      } catch (e) {
        instance.item = {}
        instance.getItemError = true
      }
    }

    const {
      item,
      loading,
      deleteItem,
    } = useCrudShow({
      item: {
        id: null,
        name: '',
        domain: '',
        description: '',
        key: '',
        mode: siteModes[0].value,
        dev_data: {
          messengers: [
            { key: 'telegram', value: '' },
            { key: 'whatsapp', value: '' },
            { key: 'viber', value: '' },
            { key: 'vk', value: '' },
          ],
          social: [
            { key: 'vk', value: '' },
            { key: 'telegram', value: '' },
            { key: 'youtube', value: '' },
            { key: 'ok', value: '' },
            { key: 'dzen', value: '' },
          ],
          font: 'Roboto',
          colors: {
            primary: 'blue',
            secondary: 'blue',
          },
          phone: '',
        },
      },
      getMoreData: async () => {
      },
      getItem,
      deleteEntity: i => deleteWebsiteApi(i.id),
      getQuestionTextBeforeDeletion: i => `Сайт <strong>${i.name}</strong> будет удален`,
      getTextAfterDeletion: () => 'Сайт был удален.',
      runAfterDeletion: () => {
        instance.$router.push({ name: 'sites' })
      },
    })

    // BEGIN Breadcrumb
    const breadcrumb = computed(() => [
      {
        text: 'Сайты',
        to: { name: 'sites' },
      },
      {
        text: get(item.value, 'name', 'Сайт'),
        active: true,
      },
    ])
    const pageTitle = computed(() => get(item.value, 'name', 'Сайт'))
    // END Breadcrumb

    const siteUrl = computed(() => {
      console.log('1')
      const apiUrl = process.env.VUE_APP_API_URL.slice(0, -4)
      const url = apiUrl
        .replace('//apiv2.', `//${item.value.key}.store.`)
        .replace('//api.', `//${item.value.key}.store.`)
        .replace(/^http(s)?:\/\//, '')

      return {
        label: url,
        path: `http://${url}`,
      }
    })

    return {
      item,
      loading,
      getItemError,
      deleteItem,
      siteModes,
      breadcrumb,
      pageTitle,

      get,

      siteUrl,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
